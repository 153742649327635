/*====================
    3.2 Header Bottom CSS
====================*/
.header-bottom {
    background-color: transparent;
    min-height: 100px;
    @media #{$extra-small-mobile} {
        min-height: 78px;
    }

    &.header-wide{
        @media #{$extraBig-device} {
            padding: 0 85px;
        }
    }
}

/* Logo */
.header-logo {
    max-width: 180px;
    width: 100%;
    @media #{$extra-small-mobile} {
        max-width: 140px;
    }
    & a {
        & img {
            width: 100%;
        }
    }
}

/* Main Menu */
.main-menu {
    display: flex;
    justify-content: flex-start;
    & > ul {
        display: flex;
        flex-wrap: wrap;

        margin-bottom: 0;
        padding-left: 0;

        list-style: none;
        & > li {
            & + li {
                margin-left: 30px;
                @media #{$desktop-device} {
                    margin-left: 25px;
                }
            }
            & > a {
                font-size: 15px;
                line-height: 100px;

                text-decoration: none;
                letter-spacing: 0.5px;
                text-transform: capitalize;

                color: $headings-color;
                &:hover,
                &.active {
                    color: $primary;
                }
            }
        }
    }

    & ul {
        & .has-children {
            position: relative;
            & > .sub-menu {
                position: absolute;
                background: $white;
                width: 220px;
                transform-origin: 0 0 0;
                transform: scaleY(0);
                transition: all 0.3s ease-in-out;
                z-index: 9;
                border: 1px solid #efefef;
                padding-left: 0;

                & > li {
                    list-style: none;
                    
                    & > a {
                        padding: 10px 25px;
                        display: block;
                        line-height: 1.7;
                        text-decoration: none;
                        font-size: 14px;
                        font-weight: 400;
                        border-bottom: 1px dashed #efefef;

                        &:hover {
                            color: $primary;
                        }
                    }

                    &:last-child {
                        & > a {
                            border-bottom: 0px;
                        }
                    }
                }
            }

            &:hover {
                & .sub-menu, .mega-menu {
                    transform: scaleY(1);
                }
            }
        }
    }
}

/* Mega Menu */
.mega-menu {
    background: $white;
    padding: 30px 15px;
    transition: all 0.3s ease-in-out;
    transform-origin: 0 0 0;
    transform: scaleY(0);
    position: absolute;
    width: 1110px;
    left: 0;
    right: 0;
    top: 100%;
    border: 1px solid #efefef;
    z-index: 99;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    @media #{$desktop-device} {
       width: 960px;
    }
    & > li {
        list-style: none;

        & .mega-menu-title {
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 10px;
            position: relative;
            display: inline-block;

            &::after {
                position: absolute;
                content: "";
                background: #efefef;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
            }
        }

       & > ul {
            padding-left: 0;

            a {
                line-height: 1;
                text-decoration: none;
                font-size: 14px;
                display: block;
                padding: 10px 0;
                font-weight: 400;

                &:hover {
                    color: $primary;
                }
            }
        }

    }
}

/* Actions */
.header-actions {
    display: flex;
    justify-content: flex-end;
    & > * {
        & + * {
            margin-left: 25px;
            @media #{$large-mobile} {
                margin-left: 20px;
            }
            @media #{$small-mobile} {
                margin-left: 15px;
            }
        }
    }
    & .header-action-btn {
        
        line-height: 1;
        position: relative;
        display: flex;
        text-decoration: none;
        color: $dark;

        &.dropdown-toggle {
            &::after {
                display: none;
            }
        }
        &:hover {
            color: $primary;
        }

        &-cart {
            padding-right: 10px;
        }
        & i {
            font-size: 26px;
            line-height: 1;
            @media #{$extra-small-mobile} {
                font-size: 24px;
            }
        }
        & .header-action-num {
            font-size: 10px;
            font-weight: 600;
            line-height: 18px;

            position: absolute;
            z-index: 2;
            top: -8px;
            right: 0;

            width: 18px;
            height: 18px;

            text-align: center;

            color: $white;
            border-radius: 50%;
            background-color: $primary;
            @media #{$extra-small-mobile} {
                font-size: 10px;
                line-height: 14px;

                width: 14px;
                height: 14px;
                top: -5px;
                right: 2px;
            }
        }
    }

    & .dropdown-user {
        position: relative;
        & .dropdown-menu-user{
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 100%;
            transition: .3s;
            width: 150px;
            background: #fff;
            border: 1px solid #efefef;
            padding: 20px 0;
            z-index: 9;
            right: 0;
            transform: translateY(30px);

            & li  {
                & a {
                    padding: 5px 20px;
                    &:hover {
                        background-color: #fff;
                        color: $primary;
                    }

                    &:active {
                        background-color: #fff;
                        color: $dark;
                        &:hover {
                            color: $primary;
                        }
                    }
                }
            }
        }
        &:hover {
            .dropdown-menu-user {
                opacity: 1;
                visibility: visible;
                transition: .3s;
                transform: translateY(0px);
            }
        }
    }

    &.header-actions-width {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;

        & .header-action-right {
            display: flex;
            justify-content: flex-end;

            & > * {
                & + * {
                    margin-left: 25px;
                    @media #{$large-mobile} {
                        margin-left: 20px;
                    }
                    @media #{$small-mobile} {
                        margin-left: 15px;
                    }
                }
            }

        }

        & .header-action-left {

            & .search-bar {
                width: 100%;
                position: relative;
        
                @media #{$laptop-device} {
                    width: 180px;
                }
            }

            & .search-bar-input {
                background-color: $gray-500;
                border-radius: 30px;
                border: 1px solid transparent;
                padding: 10px 20px 10px 50px;
                width: 100%;

                &:focus {
                    border: 1px solid $gray-400;
                    outline: 0px;
                }
            }

            & .search-bar-button {
                background: none;
                border: 0px;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 25px;
                left: 10px;
                display: flex;
            }
        }
    }
}

/* Sticky header */
.header-sticky {
    background: $white;

    &.sticky {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        width: 100%;
        transition: all .5s ease 0s;
        border-bottom: 1px solid rgba(0,0,0,0.075);
        animation: fadeInDown .5s ease-in-out;
    }
}
