/*----------------------------------------*/
/*  05. Pages CSS
/*----------------------------------------*/

/*====================
    5.1 Blog Page CSS
====================*/

.single-blog{
    & .blog-thumb {
        & a {
            position: relative;
            display: block;
            overflow: hidden;
            & img {
                width: 100%;
                transition: $transition-base;
            }
            &:hover {
                & img {
                    transform: scale(1.1);
                }
            }
        }
    }

    & .blog-content {
        & .blog-meta {
            margin: 30px 0 20px;
            & p {
                font-size: 14px;
                line-height: 1;
                color: $body-color;
                text-transform: uppercase;
            }
        }

        & .blog-title {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 0;

            @media #{$large-mobile} {
                font-size: 20px;
            }

            & a {
                color: $dark;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}
/*-- Blog Details style  --*/
.blog-details {
    & .image {
        & img {
            width: 100%;
            transition: $transition-base; 
        }
    }
    & .content{
        & .title {
            font-size: 24px;
            font-weight: 500;
            @media #{$extra-small-mobile} {
                font-size: 22px;
            }
        }
        & .meta-list {
            display: flex;
            flex-wrap: wrap;
            color: $body-color;
            align-items: center;
            & .meta-item {
                font-size: 14px;
                margin-right: 10px;
                & + .meta-item{
                    &::before{
                        content: "|";
                        margin-right: 10px; 
                    }
                }
            }
            a{
                text-transform: uppercase;
                font-weight: 500;
                &:hover{
                    color: $primary;
                }
            }
        }
        & .desc {
            & > p {
                line-height: 2;
            }
        }
    }
    & .blockquote {
        position: relative;
        background-color: #f8f8f8;
        color: $body-color;
        font-style: italic;
        margin: 25px 32px 25px 30px;
        padding: 25px;
        font-size: 16px;
        
        @media #{$small-mobile} {
            font-size: 14px;
        }
        &:before{
            background-color: $primary;
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
        }
    }
    & .share-article{
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        & .left-side{
            float: left;

            & a {
                &:hover{
                    color: $primary;
                }
            }
        }
        & .right-side{
            float: right;

            & a {
                &:hover{
                    color: $primary;
                }
            }
        }
        & .share-title{
            @media #{$extra-small-mobile} {
                display: none;
            }
        }
    }
    & .widget-social {
        text-align: center;
    }
}
.comment-area-wrapper{
    & .title{
        font-size: 24px;
        font-weight: 700;
        color: $headings-color;
    }
    & .single-comment-wrap{
        display: flex;
        flex-direction: row;
        border-radius: 8px;
        border: 1px solid transparent;
        padding: 20px;
        &.comment-reply{
            margin-left: 50px;
            @media #{$extra-small-mobile} {
                margin-left: 20px;
            }
        }
        & .author-thumb{
            margin-right: 10px;
            width: 80px;
            height: 80px;
            & img{
                width: 100%;
                border-radius: 10px;
            }
            @media #{$extra-small-mobile} {
                width: 50px;
                height: 50px;
            }
        }
        & .comments-info{
            width: 90%;
            & .author{
                font-size: 12px;
                color: $headings-color;

                & a {
                    color: $headings-color;
                    font-size: 14px;

                    &:hover {
                        color: $primary;
                    }
                }
            }
            & .btn-reply{
                font-size: 12px;
                color: $headings-color;
                &:hover{
                    color: $primary;
                }
            }
        }
    }
}
.blog-comment-form-wrapper{
    & .title{
        font-size: 24px;
        font-weight: 700;
        color: $headings-color;
    }
    & .comment-box{
        & .input-area{
            padding: 10px 15px;
            background: #f8f8f8;
            border: 1px solid transparent;
            transition: .3s;
            &:focus{
                border: 1px solid $primary;
                transition: .3s;
                outline: 0px;
                box-shadow: none;
            }
        }
    }
}