/*====================
    5.3 About Page CSS
====================*/
.about-content {
    & .about-title {
        font-size: 48px;
        color: $dark;
        display: block;
        line-height: 1.1;
        margin-bottom: 38px;
        font-weight: 400;

        @media #{$tablet-device} {
            margin-bottom: 26px;
        }

        @media #{$large-mobile} {
            font-size: 30px;
            margin-bottom: 20px;
        }
    }

    & p {
        font-size: 15px;
        line-height: 1.8;
        padding-bottom: 12px;

        @media #{$large-mobile} {
            padding-bottom: 0;
            line-height: 1.5;
            font-size: 14px;
        }

        &:last-child {
            padding-bottom: 0;
            margin-bottom: -5px;
        }
    }
}
// CTA Style 
.single-choose-item {
    & i {
        color: $primary;
        font-size: 44px;
        transition: $transition-base;

        &:hover {
            color: $secondary;
            transition: $transition-base;
        }
    }

    & .cta-title {
        color: $dark;
        font-size: 18px;
        padding: 15px 0 10px;
        text-transform: capitalize;
        font-weight: 400;
    }

    & p {
        @media #{$small-mobile} {
            width: 250px;
            margin: 0 auto;
        }
    }
}

// Team section Style 
.single-team-member {

    & .team-thumb {
        position: relative;
        overflow: hidden;

        & img {
            position: relative;
            overflow: hidden;
            transition: $transition-base;
        }

        & .team-social {
            position: absolute;
            bottom: -50px;
            width: 100%;
            padding: 10px 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: $white;
            transition: $transition-base;

            & a {
                color: $dark;
                width: 30px;
                height: 30px;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                line-height: 30px;
                background-color: #f5f5f5;
                border-radius: 5px;
                margin-right: 5px;

                &:hover {
                    background: $primary;
                    color: $white;
                    transition: $transition-base;
                }
            }
        }
    }

    & .team-content {
        & .team-member-name {
            padding-top: 15px;
            font-weight: 400;
            font-size: 16px;
        }

        & p {
            line-height: 1;
            color: $primary;
        }
    }

    &:hover {
        & .team-thumb {
            & img {
                opacity: .5;
                transition: $transition-base;
            }

            & .team-social {
                bottom: 0px;
            }
        }
    }
}