/*----------------------------------------*/
/*  02. Component CSS
/*----------------------------------------*/
/*====================
    2.1 Button CSS
====================*/
.btn {
    font-size: 15px;
    padding: 14px 30px;
    border-radius: 3px;
    border: 1px solid transparent;
    line-height: 1;
    &:focus {
        box-shadow: none;
    }
    &:active{
        &:focus{
            box-shadow: none;
        }
    }
}
/* Button Color & Style (Solid & Outline) */
@each $name, $value in $theme-colors {
    .btn-#{$name} {
        border-color: $value;
        background-color: $value;

        @if $name==warning or $name==light {
            color: $dark;
        }

        @else if $name==primary {
            color: $white;
            background-color: $primary;
            border: 1px solid transparent;
        }

        @else if $name==whited {
            color: $dark;
            background-color: $white;
            border: 1px solid transparent;
        }

        @else {
            color: $white;
        }
    }
}
@each $name, $value in $theme-colors {
    .btn-hover-#{$name} {
        &:hover {
            border-color: $value;
            background-color: $value;
            @if $name == light {
                color: $white;
                background-color: $headings-color;
                border: 1px solid transparent;
            } 
            @else if $name == warning {
                color: $headings-color;
            }
            @else {
                color: $white;
            }
        }
    }
}