/*====================
    5.8 Faq Page CSS
====================*/
.faq_content_area {
    padding: 0px 0 65px;
    @media #{$tablet-device, $large-mobile} {
        padding: 0px 0 30px;
    }
}
.accordion_area {
    & .card-header{
        background:  $primary;
        &:first-child{
            border-radius: inherit;
        }
    }
}
.card_dipult {
	border: none;
    margin-bottom: 10px;
    border-radius: 5px;
    & .collapse {
        border: 0;
    }
    & .card-body{
        & p{
            font-size: 14px;
            @media #{$extra-small-mobile} {
                font-size: 12px;
            }
        }
    }
}
.card_dipult:last-child {
	margin-bottom: 0;
}
.card-header.card_accor  {
    padding: 0;
	border: none;
    & button{
        height: 45px;
        text-decoration: none;
        cursor: pointer;
        position: relative;
        white-space: initial;
        
        @media #{$tablet-device} {
            height: 60px;
        }
        &:focus{
            box-shadow: none;
        }
        & i{
            position: absolute;
            top: 50%;
            transform: translatey(-50%);
            right: 20px;
            @media #{$tablet-device} {
                right: 10px;
            }
        }
        &.btn-link{
            border-bottom: 1px dotted  $primary;
            width: 100%;
            text-align: left;
            font-size: 14px;
            font-weight: 500;
            color: $white;
            padding-left: 20px;
            height: 100%;
            line-height: 1.6;
            padding-right: 35px;
            @media #{$tablet-device} {
                padding: 10px 28px 10px 10px;
                font-size: 13px;
            }
            @media #{$large-mobile} {
                padding: 10px 28px 10px 10px;
                font-size: 12px;
            }
            &.collapsed{
                background: $gray-100;
                border-bottom: 1px dotted $primary;
                width: 100%;
                text-align: left;
                color: $headings-color;
            }
        }
    }
}
.card-header{
    &.card_accor {
        & .btn-link {
            & i{
                &.fa-plus{
                    display: none;
                }
            }
            &.collapsed {
                & i{
                    &.fa-plus{
                        display: block;
                    }
                    &.fa-minus{
                        display: none;
                    }
                }
            }
        }
    }
}
.faq_content_wrapper{
    & .title{
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
        line-height: 1.5;
        margin-top: -8px;
        @media #{$tablet-device, $large-mobile} {
            font-size: 18px;
            line-height: 1.2;
        }
    }

    & p {
        font-size: 14px;
    }
}