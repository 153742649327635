/*====================
    5.4 Contact Page CSS5
====================*/
.contact-title {
    & .title {
        font-size: 20px;
        font-weight: 400;
    }
}
.contact-content {
    & .contact-block {
        margin: 30px 0;
        & ul {
            & li {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ddd;
                display: flex;
                flex-direction: row;
                align-items: center;

                &:last-child {
                    border-bottom: 0px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }

                & i {
                    font-size: 18px;
                    font-weight: 500;
                    margin-right: 10px;
                }

                & a {
                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }

    & .working-time {
        & .title {
            font-weight: 400;
        }
    }
}
.contact-form-wrapper{
    & .input-area{
        & .input-item, & .textarea-item{
            padding: 10px 15px;
            background: #f8f8f8;
            border: 1px solid transparent;
            transition: .3s;
            width: 100%;
            &:focus{
                border: 1px solid $primary;
                transition: .3s;
                outline: 0px;
                box-shadow: none;
            }
        }
    }
}